<template>    
  <div class="background_basic">            
    <v-card class="ma-3">
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-home-city</v-icon>구조정보
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>        
        <v-spacer></v-spacer>
        <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="blue accent-4"
        ></v-progress-linear>
      </v-toolbar>
      <v-divider></v-divider>     

      <v-row class="ma-0">        
        <v-col cols="12" sm="3" md="3">
          <v-card flat outlined height="calc(100vh - 180px)" class="mt-0 pa-0">
            <div flat dense style="text-align:center;">    
              <v-btn color="black" text @click="addMap()" >
                <v-icon big >add</v-icon>
              </v-btn>
              <v-btn color="black" text @click="updateMap()" >
                <v-icon big >update</v-icon>
              </v-btn>
              <v-btn color="black" text @click="deleteMap()" >
                <v-icon big >delete</v-icon>
              </v-btn>              
            </div>
            <v-divider></v-divider>
            <div style="overflow-x: auto; overflow-y: auto; width:100%; height: calc(100vh - 220px); padding:10px; background-color:#ffffff">              
              <v-treeview     
                  :active="selection"                
                  :items="items"
                  activatable
                  item-key="id"
                  hoverable
                  selection-type="independent"
                  dense  
                  :open.sync="open"
                  @update:active="onUpdate"
                  return-object>
                  <!-- <template v-slot:prepend="{ item }">
                    <v-icon v-if="item.iLev == -1 ">mdi-map</v-icon>
                    <v-icon v-else-if="item.iLev == 0 ">mdi-map</v-icon>
                    <v-icon v-else-if="item.iLev == 1 ">mdi-group</v-icon>
                    <v-icon v-else-if="item.iLev == 2 ">mdi-office-building</v-icon>
                    <v-icon v-else>mdi-floor-plan</v-icon>
                  </template> -->
              </v-treeview>
            </div>
          </v-card>
        </v-col>  

        <v-col cols="12" sm="9" md="9">
          <v-card flat outlined height="calc(100vh - 180px)" class="mt-0 pa-0" v-resize="onResize" >
            <v-card-title v-if = "sNowId == ''" class="justify-center">맵을 선택하세요.</v-card-title>
            <div v-else>  
              <!-- style="height: calc(100vh - 182px)" -->
              <vl-map :load-tiles-while-animating="false" :load-tiles-while-interacting="false" style="height: calc(100vh - 182px)"
                  @click="ClickMap($event.coordinate)" >
                  <vl-view :projection="projection"  :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>
                  <vl-layer-image id="xkcd">
                    <vl-source-image-static :url="imgUrl" :size="imgSize" :extent="imgExtent" :projection="projection">
                    </vl-source-image-static>

                    <vl-overlay v-for="(ApPos, i) in zApPos" :key="i" :offset="[-18, -22]" :position="[ApPos.x*fRateSize, ApPos.y*fRateSize]">            
                      <template>                
                        <div class="overlay-content">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">   
                              <div v-if="zApPos[i].cType == 'AP'" >
                                <div v-if="zApPos[i].bSel == true">                            
                                  <v-icon large v-on="on" color="red" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-access-point-network</v-icon>                                     
                                </div>
                                <div v-else>
                                  <v-icon large v-on="on" color="blue" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-access-point-network</v-icon>                                     
                                </div>  
                                <div style="font-size:14px;">{{ApPos.cMac.substring(8,12)}}</div> 
                              </div>
                              <div v-else >
                                <!-- <div v-if="zApPos[i].bSel == true">                            
                                  <v-icon large v-on="on" color="red" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-map-marker</v-icon>                                     
                                </div>
                                <div v-else>
                                  <v-icon large v-on="on" color="blue" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-map-marker</v-icon>                                     
                                </div>   -->

                                <div v-if="zApPos[i].cBuildingType == 'N'">                            
                                  <div v-if="zApPos[i].bSel == true">                            
                                    <v-icon large v-on="on" color="red" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-map-marker</v-icon>                                     
                                  </div>
                                  <div v-else>
                                    <v-icon large v-on="on" color="black" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-map-marker</v-icon>                                     
                                  </div>  
                                </div>  
                                <div v-else>                            
                                  <div v-if="zApPos[i].bSel == true">                            
                                    <v-icon large v-on="on" color="red" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-pin</v-icon>                                     
                                  </div>
                                  <div v-else>
                                    <v-icon large v-on="on" color="black" @click="SelectPoi(ApPos.cApId, ApPos.cType)">mdi-pin</v-icon>                                     
                                  </div>  
                                </div>  
                              </div>
                            </template>                    
                            <div>{{ApPos.cApId}} / {{ApPos.cMac}}</div>         
                            <div v-if="ApPos.cType=='AP'" class="mt-1 mb-1">                          
                              <v-chip v-if="ApPos.cInOut == 'NONE' " small label color="grey lighten-3">일반</v-chip>
                              <v-chip v-else-if="ApPos.cInOut == 'IN' " small label color="red lighten-2" dark>IN</v-chip>
                              <v-chip v-else-if="ApPos.cInOut == 'OUT' " small color="blue lighten-2" label dark>OUT</v-chip>
                              <v-chip v-else small label color="grey lighten-3">일반</v-chip>                                           
                            </div>
                          </v-tooltip>  
                        </div> 
                      </template>
                    </vl-overlay>
                  </vl-layer-image> 
                </vl-map>        
              </div>
          </v-card>
        </v-col>  
      </v-row>

    </v-card>
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />    
    <GetMapInfo ref="GetMapInfoRef" @onGetData="onGetData" />        
  </div>
</template>

<script>
import MsgBox from "@/components/MsgBox.vue";
import GetMapInfo from "@/components/GetMapInfo.vue";
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import EventBus from '@/eventBus.js';
import { createProj, addProj } from 'vuelayers/lib/ol-ext'


let size = [2048, 2048];
let extent = [0, 0, ...size];


let customProj = createProj({
  code: 'xkcd-image',
  units: 'pixels',
  extent,
})


addProj(customProj);

export default {
  components: {
    MsgBox,    
    GetMapInfo,
  },
  data: () => ({  
    loading : false,    
    items: [],
    fRateSize : 1,
    selection: [],    
    sNowId : "",
    sNowMapId :"",
    sNowMapGroupId : "",    
    sNowBuildingId : "",    

    zoom: BasicInfo.Map_Zoom_Default,
    center: [size[0] / 2, size[1] / 2],
    rotation: 0,
    imgSize: size,    
    imgExtent: extent,
    projection: customProj.getCode(),
    imgUrl : BasicInfo.FILE_API + "Files/NoImage.svg",
    zApPos: [], 
    open: [],    
    // items2: [
    //   {
    //     id: 1,
    //     name: 'Applications :',
    //     locked: true,
    //     children: [
    //       { id: 2, name: 'Calendar : app' },
    //       { id: 3, name: 'Chrome : app' },
    //       { id: 4, name: 'Webstorm : app' },
    //     ],
    //   }
    // ],
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo"]),
  },

  created() {
    this.initialize();
  },
  mounted () {    
    this.onResize();
  },
  methods: {
    initialize() {          
      this.Search();    
    },

    SelectPoi(){
//
    },

    onResize () {
      size = [window.innerWidth, window.innerWidth];
      extent = [0, 0, ...size];

      this.imgSize = size;
      this.imgExtent = extent;
      this.center = [size[0] / 2, size[1] / 2];

      this.fRateSize = window.innerWidth/2048;

      this.zoom = 1;
    },

    onUpdate(selection) {      
      this.selection = selection;
      this.sNowId = "";
      this.sNowMapId = "";
      this.sNowMapGroupId = "";
      this.sNowBuildingId = "";

      this.zApPos = [];
      if (selection.length > 0) {        
        this.imgUrl = selection[0].Path;        
        this.sNowId = selection[0].id;
        this.sNowMapId = selection[0].cMapId;
        this.sNowMapGroupId = selection[0].cMapGroupId;
        this.sNowBuildingId = selection[0].cBuildingId;
        this.getApPos();
      }
    },

    getApPos(){
      this.zApPos = [];
      var posData = {            
        cMapId : this.sNowMapId,
        cMapGroupId : this.sNowMapGroupId,
        cBuildingId : this.sNowBuildingId,
        cNowSiteId : this.UserInfo.NowSiteId,
      };      
      axios
        .post(BasicInfo.UIL_API + "GetApPos", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            for (let i = 0; i < res.data.Result.length; i++) {
              var item = {
                cApId: res.data.Result[i].cApId,
                x : res.data.Result[i].x,
                y : res.data.Result[i].y,
                cPosistion : res.data.Result[i].cPosistion,
                cMac : res.data.Result[i].cMac,
                cType: res.data.Result[i].cType,
                cBuildingType : res.data.Result[i].cBuildingType,
                cFloor : res.data.Result[i].cFloor,
                bSel : false,                
              }
              this.zApPos.push(item);
            }

            
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg", true, ""+err);
        });
    },
    addMap(){
      if (this.selection.length == 0) {
        EventBus.$emit("onShowMsg",true, "구조정보를 선택해주세요.");
        return;
      }

      if (this.selection[0].iLev == 3) {
        EventBus.$emit("onShowMsg",true, "선택한 구조정보로 하위 구조정보를 생성할 수 없습니다.");
        return;
      }
      this.$refs.GetMapInfoRef.open(true, "", "", this.selection[0].cMapGroupId, this.selection[0].cBuildingId, this.selection[0].name, "", BasicInfo.FILE_API + "Files/NoImage.svg", "", this.selection[0].iLev + 1, this.selection[0].cSel == 'Y', "N", "0");      
    },

    updateMap(){      
      var Path = "";
      var FileId = "";
      var Order = "";
      if (this.selection.length > 0) {                        
        Path = this.selection[0].Path;
        FileId = this.selection[0].file;
        Order = this.selection[0].Order;
        
        this.$refs.GetMapInfoRef.open(false, this.selection[0].name, this.selection[0].cMapId, this.selection[0].cMapGroupId, this.selection[0].cBuildingId, this.selection[0].cParentNm, FileId, Path, Order, this.selection[0].iLev, this.selection[0].cSel == 'Y', this.selection[0].cType, this.selection[0].cFloor);      
      } else {
        EventBus.$emit("onShowMsg",true, "수정할 구조정보를 선택하세요.");
      }
    },

    deleteMap(){
      if (this.selection.length == 0) {        
        EventBus.$emit("onShowMsg",true, "삭제할 구조정보를 선택하세요.");
        return;
      }    
      
      if (this.selection[0].iLev == 0) {        
        EventBus.$emit("onShowMsg",true, "최상위 맵은 삭제할 수 없습니다.");
        return;
      }  

      this.sNowGroupId = this.selection[0].cMapGroupId;
      this.sNowBuildingId = this.selection[0].cBuildingId;
      this.sNowMapId = this.selection[0].cMapId;
      
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",        
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.selection[0].name +          
          "]"
      );
    },

    
    Search() {         
      this.open = [];   
      this.items = [];
      this.selection = [];
      this.sNowMapId = "";
      this.zApPos = [];
      this.imgUrl = BasicInfo.FILE_API + "Files/NoImage.svg";

      var posData = {        
      };
      axios
        .post(BasicInfo.UIL_API + "GetMapInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 0) {
                let item = {
                  id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                  cMapGroupId: res.data.Result[i].cMapGroupId,
                  cBuildingId: res.data.Result[i].cBuildingId,
                  cMapId: res.data.Result[i].cMapId,
                  name: res.data.Result[i].cMapNm,
                  file: res.data.Result[i].cFileId,
                  iLev: res.data.Result[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                  Order: res.data.Result[i].cOrder,                
                  cSel : res.data.Result[i].cSel,                
                  cType : res.data.Result[i].cType,                
                  cFloor : res.data.Result[i].cFloor,                
                  cParentNm : "",
                  children: [],
                }
                this.items.push(item);                           
                this.open.push(item);
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 1) {                
                let item = {
                  id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                  cMapGroupId: res.data.Result[i].cMapGroupId,
                  cBuildingId: res.data.Result[i].cBuildingId,
                  cMapId: res.data.Result[i].cMapId,
                  name: res.data.Result[i].cMapNm,
                  file: res.data.Result[i].cFileId,
                  iLev: res.data.Result[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                  Order: res.data.Result[i].cOrder,                
                  cSel : res.data.Result[i].cSel,
                  cType : res.data.Result[i].cType,                
                  cFloor : res.data.Result[i].cFloor,                
                  cParentNm : this.items[0].name,
                  
                  children: [],
                }
                this.items[0].children.push(item);   
                this.open.push(item);                      
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 2) {
                for (let j = 0; j < this.items[0].children.length; j++) {                  
                  if (this.items[0].children[j].cMapGroupId == res.data.Result[i].cMapGroupId) {     
                    let item = {
                      id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                      cMapGroupId: res.data.Result[i].cMapGroupId,
                      cBuildingId: res.data.Result[i].cBuildingId,
                      cMapId: res.data.Result[i].cMapId,
                      name: res.data.Result[i].cMapNm,
                      file: res.data.Result[i].cFileId,
                      iLev: res.data.Result[i].iLev,
                      Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                      Order: res.data.Result[i].cOrder,
                      cSel : res.data.Result[i].cSel,
                      cType : res.data.Result[i].cType,                
                      cFloor : res.data.Result[i].cFloor,                
                      cParentNm : this.items[0].children[j].name,
                      children: [],
                    }
                    this.items[0].children[j].children.push(item);
                    break;
                  }
                }
              }              
            }

            for (let i = 0; i < res.data.Result.length; i++) {
              if (res.data.Result[i].iLev == 3) {
                for (let j = 0; j < this.items[0].children.length; j++) {    
                  if (this.items[0].children[j].cMapGroupId == res.data.Result[i].cMapGroupId) {     
                    for (let z = 0; z < this.items[0].children[j].children.length; z++) {    
                      if (this.items[0].children[j].children[z].cBuildingId == res.data.Result[i].cBuildingId) {     
                        let item = {
                          id: res.data.Result[i].cMapGroupId + res.data.Result[i].cBuildingId + res.data.Result[i].cMapId,
                          cMapGroupId: res.data.Result[i].cMapGroupId,
                          cBuildingId: res.data.Result[i].cBuildingId,
                          cMapId: res.data.Result[i].cMapId,
                          name: res.data.Result[i].cMapNm,
                          file: res.data.Result[i].cFileId,
                          iLev: res.data.Result[i].iLev,
                          Path: BasicInfo.FILE_API + res.data.Result[i].cPath,
                          Order: res.data.Result[i].cOrder,
                          cSel : res.data.Result[i].cSel,
                          cType : res.data.Result[i].cType,             
                          cFloor : res.data.Result[i].cFloor,                   
                          cParentNm : this.items[0].children[j].children[z].name,     
                          children: [],
                        }
                        this.items[0].children[j].children[z].children.push(item);
                        break;
                      }
                    }
                  }
                }
              }              
            }
            
            if (this.items.length > 0) {                      
              this.selection.push(this.items[0]);             
              this.sNowId = res.data.Result[0].cMapGroupId + res.data.Result[0].cBuildingId + res.data.Result[0].cMapId;    
              this.sNowMapId = res.data.Result[0].cMapId;
              this.sNowMapGroupId = res.data.Result[0].cMapGroupId;
              this.sNowBuildingId = res.data.Result[0].cBuildingId;

              this.imgUrl = BasicInfo.FILE_API + res.data.Result[0].cPath;
            }     
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg", true, ""+err);
        });
    },

    onMsgBoxFunc: function(result) {
      if (result === true) {
        //삭제
        var posData = {
          cUserId: this.$store.state.UserInfo.UserId,
          cMapGroupId: this.sNowMapGroupId,
          cBuildingId: this.sNowBuildingId,
          cMapId: this.sNowMapId,
        };
        axios
          .post(BasicInfo.UIL_API + "DelMapInfo", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
            if (res.data.ResultCd === "00") {
              for (let j = 0; j < this.items[0].children.length; j++) {
                if (this.items[0].children[j].cMapId == this.cMapId && 
                  this.items[0].children[j].cBuildingId == this.cBuildingId && 
                  this.items[0].children[j].cMapGroupId == this.cMapGroupId) {
                  this.items[0].children.splice(j, 1);                    
                  this.imgUrl = "";        
                  this.sNowId = "";
                  this.sNowMapId = "";
                  this.sNowMapGroupId = "";
                  this.sNowBuildingId = "";
                  
                  this.selection = [];                  
                  this.getApPos();
                  return;
                }
              }

              for (let j = 0; j < this.items[0].children.length; j++) {
                for (let x = 0; x < this.items[0].children[j].children.length; x++) {
                  if (this.items[0].children[j].children[x].cMapId == this.cMapId && 
                      this.items[0].children[j].children[x].cBuildingId == this.cBuildingId && 
                      this.items[0].children[j].children[x].cMapGroupId == this.cMapGroupId) {
                    this.items[0].children[j].children.splice(x, 1);                    
                    this.imgUrl = "";        
                    this.sNowId = "";
                    this.sNowMapId = "";
                    this.sNowMapGroupId = "";
                    this.sNowBuildingId = "";
                    this.selection = [];                  
                    this.getApPos();
                    return;
                  }
                }
              }

              for (let j = 0; j < this.items[0].children.length; j++) {
                for (let x = 0; x < this.items[0].children[j].children.length; x++) {
                  for (let z = 0; z < this.items[0].children[j].children[x].children.length; z++) {
                    if (this.items[0].children[j].children[x].children[z].cMapId == this.cMapId && 
                        this.items[0].children[j].children[x].children[z].cBuildingId == this.cBuildingId && 
                        this.items[0].children[j].children[x].children[z].cMapGroupId == this.cMapGroupId) {
                      this.items[0].children[j].children[x].children.splice(z, 1);                    
                      this.imgUrl = "";        
                      this.sNowId = "";
                      this.sNowMapId = "";
                      this.sNowMapGroupId = "";
                      this.sNowBuildingId = "";
                      this.selection = [];                  
                      this.getApPos();
                      return;
                    }
                  }
                }
              }
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },


    

    bSendSvrFile(NowSelFile){      
      var d = new Date();
      var Year = d.getFullYear(); 
      
      var fileName = NowSelFile.name;
      var Extend = Util.getExtension(fileName).toUpperCase();
      var sFileTypeId = Util.sGetId(Util.sGetFileType(NowSelFile.type));
        
      let formData = new FormData();
      formData.append('uploadfile', NowSelFile);
      return new Promise((resolve) => (
        axios.post(BasicInfo.UIL_API + "UploadFile?PublicFile=Y&Year="+Year+"&FileNm="+fileName+"&Extend="+Extend+"&MimeType="+NowSelFile.type+"&Size="+NowSelFile.size+"&FileTypeId="+sFileTypeId , 
                formData,
                {
                headers: {
                  "Authorization": this.$store.state.UserInfo.Token,
                  'Content-Type': 'multipart/form-data',
                }
              }
            ).then(res => {                 
              resolve(res);              
            }).catch(err =>{
              resolve(null);
              EventBus.$emit("onShowMsg",true, "File Send Error-Network Error!" + err);              
          })
      ));                
    },    

    async onGetData(bAdd, cMapNm, cMapId, cMapGroupId, cBuildingId, SelectionNm, cOrder, cFileId, cPath, NowSelFile, iLev, bSel, cBuildingType, cFloor) {      
      // var oldSelect = [];
      
      this.loading = true;

      var cIsInsert  = "N";

      if (bAdd) {
         cIsInsert  = "Y";
      }

      if (NowSelFile != null) {          
        var res = await this.bSendSvrFile(NowSelFile);        
        if (res.data.ResultCd == "00") {                                         
          cFileId = res.data.FileId;    
          this.imgUrl = BasicInfo.FILE_API + res.data.Path;      
        } else {
          EventBus.$emit("onShowMsg",true, "File Send Error-Network Error!");
          this.loading = false;
          return;
        }            
      }     

      

      var posData = {
          cIsInsert : cIsInsert,
          cMapId: cMapId,
          cMapNm: cMapNm,
          cMapFullNm: SelectionNm + "/" + cMapNm,
          cMapGroupId : cMapGroupId,
          cBuildingId : cBuildingId,
          iLev: iLev,          
          cOrder : cOrder,   
          cFileId : cFileId,
          cUserId : this.$store.state.UserInfo.UserId,            
          cSel : Util.sBoolToStr(bSel),
          cType : cBuildingType,
          cFloor: cFloor,
        };        

        if (iLev != 3) {
          posData.cMapFullNm = cMapNm;
        }

        if (bAdd) {
          if (iLev == 1) {
            posData.cMapId = "00";
            posData.cBuildingId = "00";
            posData.cMapGroupId = cMapId;
          }

          if (iLev == 2) {
            posData.cMapId = "00";
            posData.cBuildingId = cMapId;        
          }
        }       

      axios
        .post(BasicInfo.UIL_API + "SetMapInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          this.loading = false;
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {      
            let item = {
                id: posData.cMapGroupId + posData.cBuildingId + posData.cMapId,
                cMapGroupId: posData.cMapGroupId,
                cBuildingId: posData.cBuildingId,
                cMapId: posData.cMapId,
                name: posData.cMapNm,
                file: posData.cFileId,
                iLev: iLev,
                cFloor: cFloor,
                Path: BasicInfo.FILE_API + "Files/NoImage.svg",
                Order: posData.cOrder,                
                cSel : posData.cSel,
                cParentNm : "",
                children: [],
            }

            if (NowSelFile != null) {
              item.Path = this.imgUrl;
            }
            
            if (bAdd){
              if (iLev == 1) {                
                this.items[0].children.push(item);  
                item.cParentNm = this.items[0].name;
              } else if (iLev == 2) {
                for (let j = 0; j < this.items[0].children.length; j++) {
                  if (this.items[0].children[j].cMapGroupId == item.cMapGroupId) {
                    item.cParentNm = this.items[0].children[j].name;
                    this.items[0].children[j].children.push(item);                      
                    break;
                  }                  
                }
              } else if (iLev == 3) {
                for (let j = 0; j < this.items[0].children.length; j++) {
                  if (this.items[0].children[j].cMapGroupId == item.cMapGroupId) {
                    for (let x = 0; x < this.items[0].children[j].children.length; x++) {
                      if (this.items[0].children[j].children[x].cBuildingId == item.cBuildingId) {
                        item.cParentNm = this.items[0].children[j].children[x].name;
                        this.items[0].children[j].children[x].children.push(item);                          
                        break;
                      }
                    }
                  }
                }
              }
            } else {
              if (iLev == 1) {                
                for (let j = 0; j < this.items[0].children.length; j++) {
                  if (this.items[0].children[j].cMapGroupId == item.cMapGroupId) {                    
                    this.items[0].children[j].name = item.name;                                        
                    this.items[0].children[j].file = item.cFileId;     
                    this.items[0].children[j].cSel = item.cSel;     
                    
                    if (NowSelFile != null) {
                      this.items[0].children[j].Path = item.Path;                    
                    }
                    this.items[0].children[j].Order = item.cOrder;                                        
                    break;
                  }                  
                }
              } else if (iLev == 2) {                
                for (let j = 0; j < this.items[0].children.length; j++) {
                  if (this.items[0].children[j].cMapGroupId == item.cMapGroupId) {                    
                    for (let x = 0; x < this.items[0].children[j].children.length; x++) {
                      if (this.items[0].children[j].children[x].cBuildingId == item.cBuildingId) {
                        this.items[0].children[j].children[x].name = item.name;                                        
                        this.items[0].children[j].children[x].file = item.cFileId;                    
                        this.items[0].children[j].children[x].cSel = item.cSel;     
                        if (NowSelFile != null) {
                          this.items[0].children[j].children[x].Path = item.Path;                    
                        }
                        this.items[0].children[j].children[x].Order = item.cOrder;                                            
                        break;
                      }
                    }                  
                  }
                }
              } else if (iLev == 3) {                
                for (let j = 0; j < this.items[0].children.length; j++) {
                  if (this.items[0].children[j].cMapGroupId == item.cMapGroupId) {                    
                    for (let x = 0; x < this.items[0].children[j].children.length; x++) {
                      if (this.items[0].children[j].children[x].cBuildingId == item.cBuildingId) {
                        for (let y = 0; y < this.items[0].children[j].children[x].children.length; y++) {
                          if (this.items[0].children[j].children[x].children[y].cMapId == item.cMapId) {
                            this.items[0].children[j].children[x].children[y].name = item.name;                                        
                            this.items[0].children[j].children[x].children[y].file = item.cFileId;  
                            this.items[0].children[j].children[x].children[y].cSel = item.cSel;                       
                            if (NowSelFile != null) {
                              this.items[0].children[j].children[x].children[y].Path = item.Path;                    
                            }
                            this.items[0].children[j].children[x].children[y].Order = item.cOrder;                                                
                            break;
                          }
                        }
                      }
                    }                  
                  }
                }
              } 
            }  
            
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });      
    },

    ClickMap(){
//
    },
  }
};
</script>

