<template>
  <!-- 비밀번호 수정 다이올로그-->
  <v-row justify="center" class="ma-0">
    <v-dialog v-model="bDialog" max-width="700px" v-if="bDialog">
      <v-card >
        <v-card-title >
          <span class="font-weight-bold">구조정보입력</span>
        </v-card-title>
        
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-text-field dense v-model="cMapId" label="아이디" max-length="2" :disabled="!bAdd" hint="2자리의 아이디를 입력하세요."></v-text-field>                
              </v-col> 
              <v-col cols="12" sm="12" md="4">
                <v-text-field dense v-model="cMapNm" label="구조명칭" ></v-text-field>
                <div v-if = "SelectionName != ''">
                  {{SelectionName}}의 하위 구조
                </div>              
              </v-col> 
              <v-col cols="12" sm="12" md="4">
                <v-text-field dense v-model="cOrder" label="순서" ></v-text-field>                
              </v-col> 
              <v-col cols="12" sm="12" md="4">
                <v-switch color="black" v-model="bSel" inset label="선택 가능 여부" class="mt-0"></v-switch>
              </v-col> 
              <v-col cols="12" sm="12" md="4">                
                <v-select :disabled="iLev != 2 && iLev != 3" v-model="cBuildingType" class="mt-n4 mb-n5" label="건물유형" :items="zBuildingType" item-value="sId" item-text="sNm"></v-select>                
              </v-col> 
              <v-col cols="12" sm="12" md="4">
                <v-text-field dense v-model="cFloor" label="층" ></v-text-field>                
              </v-col> 
              <v-col cols="12" sm="12" md="12">                      
                <v-file-input     
                  dense                   
                  show-size      
                  v-model="NowSelFile"    
                  accept=".svg,.png,.jpg,.jpeg"                                 
                  v-on:change="onSelFile(NowSelFile)"                                 
                  label="이미지"></v-file-input>
              </v-col>      
              <v-col cols="12" sm="12" md="12">   
                <v-card flat outlined>                        
                  <v-img 
                    contain                          
                    height="300"
                    :src="sNowPath">     
                  </v-img>                          
                </v-card>                     
              </v-col>     
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>                     
          <v-btn color="blue darken-2" text @click="save" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">save</v-icon>확인
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" text @click="bDialog=false" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">cancel</v-icon>취소
          </v-btn>
        </v-card-actions>              
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { mapState } from "vuex";
import EventBus from '@/eventBus.js';


export default {
  //ai파일에서 변환시 SVG는 width="2048" height="2048"
  data: () => ({
    bDialog: false,
    cMapNm: "",
    cMapId : "",
    cMapGroupId : "", 
    cBuildingId : "", 
    cFloor : "", 
    file: "",
    cTitle : "",
    SelectionId : "",
    SelectionName : "",
    cTextInputLabel : "",
    NowSelFile : null,
    cFileId : "",
    sNowPath : "",
    iLev : 0,
    bAdd : false,
    bSel : true,    
    cBuildingType : "N",    
  }),

  computed: {
    ...mapState(["zBuildingType"]),
  },


  mounted() {
    this.NowSelFile = null;
    this.bDialog = false;
  },

  methods: {
    open(bAdd, cDefault, cMapId, cMapGroupId, cBuildingId, SelectionName, cFileId, NowPath, cOrder, iLev, bSel, cBuildingType, cFloor) {
      console.log(bAdd, cDefault, cMapId, cMapGroupId, cBuildingId, SelectionName, cFileId, NowPath, cOrder, iLev, bSel, cBuildingType);
      this.bAdd = bAdd;            
      this.cMapId = cMapId;
      this.SelectionName = SelectionName;      
      this.cMapNm = cDefault;
      this.sNowPath = NowPath;  
      this.cFileId = cFileId;
      this.cOrder = cOrder;
      this.iLev = iLev;
      this.NowSelFile = null;
      this.bSel = bSel;
      this.cFloor = cFloor;
      this.cBuildingType = cBuildingType;

      if (bAdd) {
        this.cMapGroupId = "00";
        this.cBuildingId = "00";

        if (this.iLev == 2) {
          this.cMapGroupId = cMapGroupId;
        }
        
        if (this.iLev == 3) {
          this.cMapGroupId = cMapGroupId;
          this.cBuildingId = cBuildingId;
        }      
      } else {
        this.cMapGroupId = cMapGroupId;
        this.cBuildingId = cBuildingId;
      }
      this.bDialog = true;         
    },
    
    save() {
      if (this.cMapId.length != 2 ) {
        EventBus.$emit("onShowMsg",true, "아이디를 입력해주세요.(2자리)");
        return;
      }
      if (this.cMapNm == "" ) {
        EventBus.$emit("onShowMsg",true, "명칭을 입력해주세요.");
        return;
      }
      this.bDialog = false;      
      this.$emit("onGetData", this.bAdd, this.cMapNm, this.cMapId, this.cMapGroupId, this.cBuildingId, this.SelectionName, this.cOrder, this.cFileId, this.sNowPath, this.NowSelFile, this.iLev, this.bSel, this.cBuildingType, this.cFloor);      
    },

    onSelFile(NowSelFile) {            
      this.loading = true;      
      const reader = new FileReader
      reader.onload = e => {
        var imageData = e.target.result;
        this.loading = false;
        this.sNowPath = imageData;                    
      }
      try {
        reader.readAsDataURL(NowSelFile)        
      } catch (error) {
        console.log("GetMapInfo.vue(onSelFile):" + error);
      }
      
      this.loading = false;
    },    
  },
}
</script>